.container {
    margin-bottom: 12px;
    padding: 0 12px;
}

.days {
    height: 18px;
    width: 46px;
    color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .days {
    color: var(--mantine-color-white-3);
}

.compact-stack {
    height: 20px;
    color: var(--mantine-color-gray-7);
}

[data-mantine-color-scheme='dark'] .compact-stack {
    color: var(--mantine-color-white-3);
}

.street {
    height: 20px;
}

[data-mantine-color-scheme='dark'] .street {
    color: var(--mantine-color-white-1);
}

.hours-container {
    padding-left: 12px;
}

.link {
    color: var(--mantine-color-blue-9);
    text-decoration-line: underline;
    margin-left: 12px;
}

.scrollarea {
    padding-top: 16px;
}

.info-banner {
    background: var(--mantine-color-gray-1);
    margin-top: 16px;
    padding: 12px 16px;
    line-height: var(--text-line-height);
}

[data-mantine-color-scheme='dark'] .info-banner {
    background: var(--mantine-color-gray-6);
    color: var(--mantine-color-dark-0);
}
