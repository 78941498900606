.header-container {
    margin-top: 10px;
}

.header-desc {
    font-size: var(--mantine-font-size-sm);
    color: var(--mantine-color-black-3);
}

[data-mantine-color-scheme='dark'] .header-desc {
    color: var(--mantine-color-white-3);
}

.logo {
    height: 46px;
    width: auto;
    margin-right: 20px;
}

.title {
    font-size: var(--mantine-font-size-lg);
}

@media (max-width: 353px) {
        .header-desc-container-bwb {
            margin-top: 12px;
        }
}
