.action-buttons-root {
    position: absolute;
    bottom: 50%;
    right: 16px;
}

.action-button-menu {
    position: absolute;
    top: 16px;
    left: 16px;
}