:root{
    --text-line-height: 18px;
}

.title {
    line-height: var(--text-line-height);
    margin-left: 12px;
}

.description {
    background: var(--mantine-color-gray-1);
    padding: 12px 8px;
    line-height: var(--text-line-height);
}

[data-mantine-color-scheme='dark'] .description{
    background: var(--mantine-color-gray-6);
    color: var(--mantine-color-dark-0);
}
