.container {
    touch-action: none;
}

[data-mantine-color-scheme='dark'] .container {
    background-color: var(--mantine-color-dark-6);
}

.container-flex {
    height: 100%;
    width: 100%;
}

.container-logo {
    background-color: var(--logo-yellow);
    width: 100%;
    flex: 9;
    min-width: 350px;
}

.logo {
    height: 150px;
    width: 150px;
}

.container-companies {
    background-color: var(--mantine-color-dark-0);
    flex: 1;
    width: 100%;
    min-width: 350px;
    max-width: 600px;
}

[data-mantine-color-scheme='dark'] .container-companies {
    background-color: var(--mantine-color-dark-6);
}

.info-stack {
    width: 50%;
    height: 100%;
}

.logo-bvg {
    height: 44px;
    width: auto;
}

.logo-bsr {
    max-height: 34px;
    width: auto;
}

.logo-bwb {
    height: 34px;
    width: auto;
}