.header-container {
    padding: 16px 16px 16px 22px;
}

.app-logo {
    height: 38px;
    width: auto;
}

.close-button {
    color: var(--mantine-color-dark-9);
}

[data-mantine-color-scheme='dark'] .close-button  {
    color: var(--mantine-color-dark-0);
}

.content-container {
    height: stretch;
    display: flex;
    flex-direction: column;
}

.version-container {
    width: 100%;
    position: sticky;
    bottom: 0;
    flex: 1;
    background-color: var(--mantine-color-dark-0);
}

[data-mantine-color-scheme='dark'] .version-container {
    background-color: var(--mantine-color-dark-7);
}

.version-footer-text {
    padding: 0 0 16px 20px;
}

.header-title {
    line-height: normal;
    font-size: var(--mantine-font-size-xl);
}