.button {
    font-size: 16px;
    height: 44px;
    border-radius: 10px;
}

.primary-button {
    color: var(--mantine-color-black-7);
}

[data-mantine-color-scheme='dark'] .primary-button {
    color: var(--mantine-color-black-7);
}

.primary-button-disabled {
    background-color: var(--mantine-color-gray-3);
    color: var(--mantine-color-gray-5);
}

[data-mantine-color-scheme='dark'] .primary-button-disabled {
    background-color: var(--mantine-color-gray-6);
    color: var(--mantine-color-gray-5);
}

.secondary-button {
    color: var(--mantine-color-dark-5);
    border: solid 1px var(--mantine-color-gray-5);
}

[data-mantine-color-scheme='dark'] .secondary-button {
    border: solid 1px var(--mantine-color-dark-0);
    color: var(--mantine-color-dark-0);
}

.secondary-button-disabled {
    color: var(--mantine-color-gray-5);
    border: solid 1px var(--mantine-color-gray-5);
}

[data-mantine-color-scheme='dark'] .secondary-button-disabled {
    color: var(--mantine-color-gray-5);
}
