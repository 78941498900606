.sheet {
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.header {
    width: 100%;
    padding-top: 12px;
}

.handle {
    margin: 0 auto;
    border: none;
    border-radius: 5px;
    width: 50px;
    height: 4px;
    background-color: var(--mantine-color-black-0);
}

.container {
    padding: 0 8px;
    margin: 0 16px;
}

.backdrop {
    background-color: rgba(0 0 0 60%) !important;
}

.no-backdrop {
    display: none;
}