.container{
    background-color: var(--mantine-color-white-1);
}

[data-mantine-color-scheme='dark'] .container {
    background-color: var(--mantine-color-dark-5);
}

.logo {
    height: 64px;
    width: auto;
}
