@keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }
    
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }


.marker {
    fill: var(--mantine-color-black-8); 
}

[data-mantine-color-scheme='dark'] .marker {
  fill: var(--mantine-color-bvg-6);
}

.bg-circle {
    fill: var(--mantine-color-white-0);
    opacity: .54;
}

.pulse-animation {
    animation: pulse 4s ease-in-out infinite;
    fill: lightblue;
    transform-origin: center;
  }

.tip {
    fill: var(--mantine-color-black-9);
}