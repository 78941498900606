@font-face {
    font-family: Transit;
    src: local('Transit'),
        url('../assets/fonts/BVG_Transit_Web/Regular/TransitWeb.eot');
}

/* for WOFF-capable browsers */
@font-face {
    font-family: Transit;
    src: local('Transit'),
        url('../assets/fonts/BVG_Transit_Web/Regular/TransitWeb.woff') format('woff');
}

@font-face {
    font-family: TransitPict;
    src: local('TransitPict'),
        url('../assets/fonts/BVG_Transit_Web/Pict/Transit-Pict.eot');
}

/* for WOFF-capable browsers */
@font-face {
    font-family: TransitPict;
    src: local('TransitPict'),
        url('../assets/fonts/BVG_Transit_Web/Pict/Transit-Pict.woff') format('woff');
}

body {
    font-family: Transit, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}