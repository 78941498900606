.container{
    background-color: var(--mantine-color-white-1);
}

[data-mantine-color-scheme='dark'] .container {
    background-color: var(--mantine-color-dark-5);
}

.title {
    font-size: var(--mantine-font-size-lg);
}

.link {
    text-decoration: underline;
}

.link-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    width: fit-content;
    flex-wrap: nowrap;
    padding: 4px;
}