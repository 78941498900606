.logo {
    height: 32px;
    width: auto;
}

.card {
    box-shadow: 0 4px 9px 2px rgba(37 37 37 / 7%), 0 3px 4px 1px rgba(37 37 37 / 7%), 0 1px 1px 0 rgba(37 37 37 / 7%);
}

[data-mantine-color-scheme='dark'] .card {
    background-color: var(--mantine-color-dark-4);
}

.card:focus {
    border: 2px solid black;
}

[data-mantine-color-scheme='dark'] .card:focus {
    border: 2px solid white;
}
