.navlink {
    height: 40px;
}

.navlink-chevron {
    height: 24px !important;
    width: auto !important;
}

.nav-text {
    font-size: var(--mantine-font-size-lg);
    padding-left: 12px;
}
